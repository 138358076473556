import menu from "./menu";
import meta from "./meta";
import footer from "./footer";

const data = {
  menu,
  meta,
  components: [
    {
      name: "Wrapper",
      components: [
        {
          name: "AllSponsors",
          props: {
            items: [
              {
                image: "/sponsors/consell-formentera.png",
                title: "Consell Formentera",
                url: "https://www.consellinsulardeformentera.cat/",
              },
              {
                image: "/sponsors/caixabank.svg",
                title: "Caixabank",
                url: "https://www.caixabank.es/",
              },
              {
                image: "/sponsors/el-periodico.svg",
                title: "El Periódico",
                url: "https://www.elperiodico.com/es/",
              },
              {
                image: "/sponsors/warner.png",
                title: "Warner Music",
                url: "https://www.warnermusic.es/",
              },
              {
                image: "/sponsors/vasava.svg",
                title: "Vasava",
                url: "https://www.vasava.es/",
                width: "w-1/3 md:1/3",
              },
              {
                image: "/sponsors/manifiesto.png",
                title: "Manifiesto",
                url: "https://manifiesto.biz/es",
              },
              {
                image: "/sponsors/graffica.png",
                title: "Graffica",
                url: "http://graffica.info/",
              },
              {
                image: "/sponsors/miniProa.png",
                title: "Vueling",
                url: "https://www.mini.es/",
              },
              {
                image: "/sponsors/radio3.png",
                title: "Radio-3",
                url: "https://www.rtve.es/radio/radio3/",
                width: "w-3/5 md:w-2/4",
              },
              {
                image: "/sponsors/marketingdirecto.svg",
                title: "MarketingDirecto",
                url: "https://www.marketingdirecto.com/",
              },
              {
                image: "/sponsors/ib3.svg",
                title: "IB3",
                url: "https://ib3.org/",
                width: "w-1/2 md:w-1/3",
              },
              {
                image: "/sponsors/diariodeibiza_gray.png",
                title: "Diario de Ibiza",
                url: "https://www.diariodeibiza.es/",
              },
              {
                image: "/sponsors/collegi-periodistes-catalunya.png",
                title: "Col·legi de Periodistes de Catalunya",
                url: "https://www.periodistes.cat/",
              },
              {
                image: "/sponsors/trasmapi.png",
                title: "Trasmapi",
                url: "https://www.trasmapi.com/",
              },

              {
                image: "/sponsors/save-posidonia.png",
                title: "Save Posidonia Project",
                url: "https://www.saveposidoniaproject.org/",
                width: "w-1/2 md:w-3/4",
              },
              {
                image: "/sponsors/regalo-publicidad.png",
                title: "RegaloPublicidad",
                url: "https://www.regalopublicidad.com/",
              },
              {
                image: "/sponsors/estrella-galicia.png",
                title: "Estrella Galicia",
                url: "https://estrellagalicia.es/",
                width: "w-3/4",
              },
              {
                image: "/sponsors/vueling.svg",
                title: "Vueling",
                url: "https://www.vueling.com/es/",
              },
            ],
          },
        },
        footer,
      ],
    },
  ],
};

export default data;
